import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';

import { MdSearch } from 'react-icons/md';
import { FaTags } from 'react-icons/fa';

import {
  InputSearch,
  ButtonLink,
  HeaderBackground,
  HeaderPage,
  HeaderInfo,
  Table,
  Pagination,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Position,
  Button,
  Loader,
  TableResponsive,
  PopUpMenu,
  Can,
  Status,
} from 'components';

import { ModalHandles } from 'components/Modal';

import api from 'services/api';

import { useAuth, useToast } from 'contexts';

import * as S from './styles';

interface User {
  id: string;
  name: string;
  email: string;
  password: string;
  permission_users: [{ name: string }];
  rls_users: [{ name: string }];
  rls: string;
  acesso_formatado?: string;
  rls_formatado?: string;
  enabled: string;
}

interface Paginate {
  data: User[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

const UserList: React.FC = () => {
  const [users, setUsers] = useState<Paginate>();
  const [selectedUser, setSelectedUser] = useState<User>();
  const { addToast } = useToast();
  const { tokenAdmin, changeUser } = useAuth();

  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const modalRef = useRef<ModalHandles>(null);
  const [modalType, setModalType] = useState('remove');
  const history = useHistory();

  const handleSearch = useCallback(
    async ({ page, per_page }) => {
      try {
        setLoading(true);

        const params = {
          page,
          per_page,
          term,
        };

        const response = await api.get<Paginate>('/users', { params });

        const { data } = response;

        const newData: Paginate = {
          ...data,
          data: data.data.map((item: User) => ({
            ...item,
            acesso_formatado:
              item.permission_users?.length > 0
                ? item.permission_users
                    .map((permission) => String(permission.name))
                    .join(', ')
                : '',
            rls_formatado:
              item?.rls_users?.length > 0
                ? item.rls_users.map((rls) => String(rls.name)).join(', ')
                : '',
          })),
        };

        setUsers(newData);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ops...',
          description: 'Ops, ocorreu um erro no processo de pesquisa',
        });
      } finally {
        setLoading(false);
      }
    },
    [term, addToast],
  );

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 10,
    };

    handleSearch(params);
  }, [handleSearch]);

  const handleRemove = useCallback(async () => {
    try {
      await api.delete(`/users/${selectedUser?.id}`);

      if (!users) return;
      const newList = users.data.filter((item) => item.id !== selectedUser?.id);

      setUsers({ ...users, data: newList });

      modalRef.current?.closeModal();

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Usuario removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao inserir um novo registro',
      });
    }
  }, [addToast, selectedUser?.id, users]);

  const handleChangeStatus = useCallback(async () => {
    try {
      await api.get(`/users/enable/${selectedUser?.id}`);

      if (!users) return;

      const newList = users.data.map((item) => {
        if (item.id === selectedUser?.id) {
          if (item.enabled === 'S') {
            return { ...item, enabled: 'N' };
          }

          return { ...item, enabled: 'S' };
        }

        return item;
      });

      setUsers({ ...users, data: newList });

      modalRef.current?.closeModal();

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Título baixado com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao baixar o título',
      });
    }
  }, [addToast, selectedUser?.id, users]);

  const handleChangeUser = async (user_id: string): Promise<void> => {
    try {
      changeUser(user_id);
      history.push(`/`);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops...',
        description: 'Ops, ocorreu um erro no processo!',
      });
    }
  };

  const handleOpenModal = useCallback(
    (userSelected: User, type: string): void => {
      setModalType(type);

      setSelectedUser(userSelected);

      modalRef.current?.openModal();
    },
    [],
  );

  const renderModal = useMemo(() => {
    if (modalType === 'remove') {
      return (
        <Modal ref={modalRef} options={{ width: '400px' }}>
          <ModalHeader>
            <ModalTitle>Você Confirma a EXCLUSÃO?</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <p>O registro será removido completamente!</p>
          </ModalBody>
          <ModalFooter>
            <Position align="right">
              <Button
                variant="outline-secondary"
                onClick={() => modalRef.current?.closeModal()}
              >
                Cancelar
              </Button>
              <Button
                variant="primary"
                onClick={() => handleRemove()}
                style={{ marginLeft: '16px' }}
              >
                SIM, confirmo a exclusão
              </Button>
            </Position>
          </ModalFooter>
        </Modal>
      );
    }

    if (modalType === 'status') {
      return (
        <Modal ref={modalRef} options={{ width: '400px' }}>
          <ModalHeader>
            <ModalTitle>Confirma alteração do status?</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {selectedUser?.enabled === 'S' ? (
              <p>O usuario será inativado!</p>
            ) : (
              <p>O usuário será ativado!</p>
            )}
          </ModalBody>
          <ModalFooter>
            <Position align="right">
              <Button
                variant="outline-secondary"
                onClick={() => modalRef.current?.closeModal()}
              >
                Cancelar
              </Button>
              <Button
                variant="primary"
                onClick={() => handleChangeStatus()}
                style={{ marginLeft: '16px' }}
              >
                SIM, confirmo a alteração
              </Button>
            </Position>
          </ModalFooter>
        </Modal>
      );
    }

    return <></>;
  }, [handleChangeStatus, handleRemove, modalType, selectedUser?.enabled]);

  return (
    <S.Container>
      <HeaderBackground>
        <HeaderPage>
          <HeaderInfo title="Usuários" icon={FaTags} />

          <S.ContainerSearch>
            <InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) =>
                e.key === 'Enter'
                  ? handleSearch({ page: 1, per_page: users?.per_page })
                  : {}
              }
            />
          </S.ContainerSearch>

          <div>
            <Can checkAccess="users_create">
              <ButtonLink title="Adicionar usuário" to="/users/add">
                Adicionar
              </ButtonLink>
            </Can>
          </div>
        </HeaderPage>
      </HeaderBackground>

      <S.Panel>
        {loading ? (
          <Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <TableResponsive>
              <Table>
                <thead>
                  <tr>
                    <th className="text-center">Status</th>
                    <th className="text-left">Usuário</th>
                    <th className="text-left">Email</th>
                    <th className="text-left">Permissão</th>
                    <th className="text-left">RLS</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.data.map((item: User) => (
                      <tr key={item.id}>
                        <td className="text-center">
                          <Status enable={String(item.enabled === 'S')} />
                        </td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.acesso_formatado}</td>
                        <td>{item.rls_formatado}</td>
                        <td className="text-center">
                          <PopUpMenu>
                            <Can checkAccess="users_update">
                              <button
                                type="button"
                                onClick={() =>
                                  history.push(`/users/edit/${item.id}`)
                                }
                              >
                                <S.IconEdit
                                  title="Editar"
                                  size={24}
                                  color="#707070"
                                />
                                Editar
                              </button>
                            </Can>

                            <Can checkAccess="users_delete">
                              <button
                                type="button"
                                onClick={() => handleOpenModal(item, 'remove')}
                              >
                                <S.IconDelete
                                  title="Remover"
                                  size={24}
                                  color="#707070"
                                />
                                Remover
                              </button>
                            </Can>

                            <Can checkAccess="users_update">
                              <button
                                type="button"
                                onClick={() => handleOpenModal(item, 'status')}
                              >
                                <S.IconStatus
                                  title="Ativar/Desativar"
                                  size={24}
                                  color="#707070"
                                />
                                Status
                              </button>
                            </Can>

                            {tokenAdmin === '' ? (
                              <Can checkAccess="users_update">
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleChangeUser(item.id);
                                  }}
                                >
                                  <S.IconView
                                    title="Editar"
                                    size={24}
                                    color="#707070"
                                  />
                                  View
                                </button>
                              </Can>
                            ) : (
                              <></>
                            )}
                          </PopUpMenu>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </TableResponsive>
            <S.FooterContainer>
              {users && (
                <Pagination
                  page={users?.page}
                  total={users?.total}
                  per_page={users?.per_page}
                  last_page={users?.last_page}
                  onSearch={handleSearch}
                />
              )}
            </S.FooterContainer>
          </>
        )}
      </S.Panel>
      {renderModal}
    </S.Container>
  );
};

export default UserList;
