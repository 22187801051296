import React, { ReactElement } from 'react';

import { MdDashboard, MdAssignment, MdPieChart } from 'react-icons/md';
import { GoSettings } from 'react-icons/go';

import { useSiedeBar } from 'contexts/SideBarProvider';
import { useAuth } from 'contexts/AuthProvider';

import ItemMenu from './ItemMenu';

import { groupBy } from '../../utils/groupBy';

import { Container, MainMenu } from './styles';

interface Menu {
  name: string;
  route: string;
  icon?: ReactElement | null;
  children: Menu[];
  perm?: string;
  type: 'access' | 'dashboard';
}

interface Permission {
  id: string;
  category: string;
  name: string;
  report_id: string;
}

const SideBar: React.FC = () => {
  const { user, permissions, access } = useAuth();
  const { isOpened } = useSiedeBar();

  const menus: Menu[] = [];

  const newPermissions = groupBy(permissions, 'category');

  const permissionsKeys = Object.keys(newPermissions).sort();

  const userDashboardMenu: Menu[] = permissionsKeys.map((key) => {
    return {
      type: 'dashboard',
      name: `${key}`,
      route: '#',
      icon: <MdPieChart size={24} color="#707070" />,
      children: newPermissions[`${key}`]
        ?.map((perm: Permission) => {
          return {
            name: perm.name,
            type: 'dashboard',
            route: `/embedded/${perm.id}/${perm.report_id}`,
            icon: null,
            children: [],
          };
        })
        .sort((a: Permission, b: Permission) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        }),
    };
  });

  const userAccessMenu: Menu[] = [];

  if (access.includes('dashboard_list')) {
    userAccessMenu.push({
      name: 'Dashboard',
      route: '#',
      type: 'access',
      icon: <MdDashboard size={24} color="#707070" />,
      children: [
        {
          name: 'Home',
          route: '/dashboard',
          icon: null,
          type: 'access',
          perm: 'dashboard_list',
          children: [],
        },
        {
          name: 'Resumo Sessões',
          route: '/dashboard/session/resume',
          icon: null,
          type: 'access',
          perm: 'dashboard_list',
          children: [],
        },
      ],
    });
  }

  if (
    access.includes('users_list') ||
    access.includes('permissions_list') ||
    access.includes('rls_list') ||
    access.includes('layout_list')
  ) {
    userAccessMenu.push({
      name: 'Cadastros',
      route: '#',
      type: 'access',
      icon: <GoSettings size={24} color="#707070" />,
      children: [
        {
          name: 'Layout',
          route: '/config/layout',
          icon: null,
          type: 'access',
          perm: 'layout_list',
          children: [],
        },
        {
          name: 'Permissões',
          route: '/permissions',
          icon: null,
          type: 'access',
          perm: 'permissions_list',
          children: [],
        },
        {
          name: 'Rls',
          route: '/rls',
          icon: null,
          type: 'access',
          perm: 'rls_list',
          children: [],
        },
        {
          name: 'Usuários',
          route: '/users',
          icon: null,
          type: 'access',
          perm: 'users_list',
          children: [],
        },
      ],
    });
  }

  const adminMenu: Menu[] = [
    {
      name: 'Dashboard',
      route: '#',
      type: 'access',
      icon: <MdDashboard size={24} color="#707070" />,
      children: [
        {
          name: 'Home',
          route: '/dashboard',
          icon: null,
          type: 'access',
          perm: 'dashboard_list',
          children: [],
        },
        {
          name: 'Resumo Sessões',
          route: '/dashboard/session/resume',
          icon: null,
          type: 'access',
          perm: 'dashboard_list',
          children: [],
        },
      ],
    },
    {
      name: 'PowerBI',
      route: '#',
      icon: <MdAssignment size={24} color="#707070" />,
      type: 'access',
      children: [
        {
          name: 'Conta',
          route: '/account',
          icon: null,
          children: [],
          type: 'access',
        },
        {
          name: 'Categoria',
          route: '/categories',
          icon: null,
          children: [],
          type: 'access',
        },
        {
          name: 'Workspace',
          route: '/workspaces',
          icon: null,
          children: [],
          type: 'access',
        },
        {
          name: 'Relatorio',
          route: '/reports',
          icon: null,
          children: [],
          type: 'access',
        },
      ],
    },
    {
      name: 'Cadastros',
      route: '#',
      type: 'access',
      icon: <GoSettings size={24} color="#707070" />,
      children: [
        {
          name: 'Layout',
          route: '/config/layout',
          icon: null,
          type: 'access',
          children: [],
        },
        {
          name: 'Permissões',
          route: '/permissions',
          icon: null,
          type: 'access',
          children: [],
        },
        {
          name: 'Rls',
          route: '/rls',
          icon: null,
          type: 'access',
          children: [],
        },
        {
          name: 'Usuários',
          route: '/users',
          icon: null,
          type: 'access',
          children: [],
        },
      ],
    },
  ];

  if (user.role === 'admin') {
    adminMenu.map((adm) => menus.push(adm));
  }

  userAccessMenu.map((usr) => menus.push(usr));
  userDashboardMenu.map((usr) => menus.push(usr));

  return (
    <Container isMenuOpened={isOpened}>
      <MainMenu>
        {menus.map((menu: Menu) => (
          <ItemMenu key={menu.name} menu={menu} />
        ))}
      </MainMenu>
    </Container>
  );
};

export default SideBar;
