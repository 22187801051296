import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';

import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';

import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';

import { useToast } from 'contexts/ToastProvider';

import { getUser, createUser, updateUser } from 'pages/User/api';
import { getPermissions } from 'pages/Permission/api';
import { getRlss } from 'pages/Rls/api';

import * as C from 'components';
import * as S from './styles';

import { IUser, IUserForm } from '../interfaces';

const UserForm: React.FC = () => {
  const { id } = useParams<IRouterParams>();

  let schema;

  if (id) {
    schema = Yup.object().shape({
      name: Yup.string().required('Relatório obrigatório'),
      email: Yup.string()
        .required('Email obrigatório')
        .email('Digite um e-mail válido'),
      permission_users: Yup.array()
        .of(
          Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string(),
            })
            .required('Permissão obrigatória'),
        )
        .required('Permissão obrigatória')
        .typeError('Permissão obrigatória'),
    });
  } else {
    schema = Yup.object().shape({
      name: Yup.string().required('Relatório obrigatório'),
      email: Yup.string()
        .required('Email obrigatório')
        .email('Digite um e-mail válido'),
      password: Yup.string().required('Senha obrigatória'),
      confirm_password: Yup.string()
        .required('Confirmação de senha obrigatoria')
        .oneOf([Yup.ref('password'), 'Confirmação invalida']),
      permission_users: Yup.array()
        .of(
          Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string(),
            })
            .required('Permissão obrigatória'),
        )
        .required('Permissão obrigatória')
        .typeError('Permissão obrigatória'),
    });
  }

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<IUser>();
  const history = useHistory();

  const loadOptionsPermissions = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getPermissions({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsRls = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getRlss({
        page: 1,
        per_page: 25,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const onSubmit = useCallback(
    async (data: IUserForm) => {
      try {
        setLoading(true);

        if (id) {
          const response = await updateUser(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/users');
          }
        } else {
          await createUser(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/users');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, id, reset],
  );

  useEffect(() => {
    if (id) {
      getUser(id).then((data) => {
        reset(data);
        setUser(data);
      });
    }
  }, [id, reset]);

  const renderPassword = useCallback(() => {
    return (
      <>
        <C.FormGroup>
          <C.Label>Senha</C.Label>
          <C.Input
            {...register('password')}
            type="password"
            errors={errors.password}
            name="password"
            placeholder="Senha"
            autoComplete="off"
          />
        </C.FormGroup>

        <C.FormGroup>
          <C.Label>Confirmação Senha</C.Label>
          <C.Input
            {...register('confirm_password')}
            type="password"
            errors={errors.confirm_password}
            name="confirm_password"
            placeholder="Confirmação da senha"
            autoComplete="off"
          />
        </C.FormGroup>
      </>
    );
  }, [errors.confirm_password, errors.password, register]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/users">
              <MdKeyboardBackspace size={24} color="#fff" />
              Usuários
            </Link>
          </S.BackLink>
          <C.HeaderInfo
            title={id ? `Editando: ${user?.name}` : 'Novo Usuário'}
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.FormGroup>
            <C.Label>Usuário</C.Label>
            <C.Input
              {...register('name')}
              type="text"
              errors={errors.name}
              name="name"
              placeholder="Usuário"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Email</C.Label>
            <C.Input
              {...register('email')}
              type="text"
              errors={errors.email}
              name="email"
              placeholder="Email"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Permissao</C.Label>
            <C.ReactSelectAsync
              {...register('permission_users')}
              control={control}
              errors={errors.permission_users}
              loadOptions={loadOptionsPermissions}
              isClearable
              isSearchable
              isMulti
              loadingMessage="Buscando..."
              noOptionsMessage="Nenhum registro localizado"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>RLS</C.Label>
            <C.ReactSelectAsync
              {...register('rls_users')}
              control={control}
              errors={errors.rls_users}
              loadOptions={loadOptionsRls}
              isClearable
              isSearchable
              isMulti
              loadingMessage="Buscando..."
              noOptionsMessage="Nenhum registro localizado"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Nome RLS</C.Label>
            <C.Input
              {...register('rls')}
              type="text"
              errors={errors.rls}
              name="rls"
              placeholder="RLS se houver"
            />
          </C.FormGroup>

          {id ? <></> : renderPassword()}

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default UserForm;
