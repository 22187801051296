import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LayoutForm from './LayoutForm';

const Config: React.FC = () => {
  return (
    <Switch>
      <Route path="/config/layout" component={LayoutForm} isPrivate exact />
    </Switch>
  );
};

export default Config;
