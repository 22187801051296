import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';

import Account from '../pages/Account';
import Category from '../pages/Category';
import Workspace from '../pages/Workspace';
import Report from '../pages/Report';

import Permission from '../pages/Permission';
import User from '../pages/User';
import Rls from '../pages/Rls';
import Config from '../pages/Config';

import Embedded from '../pages/Embedded';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Redirect path="/" to="/signin" exact />
      <Route path="/signin" component={SignIn} />
      <Route path="/signup" component={SignUp} />

      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />

      <Route path="/account" component={Account} isPrivate />
      <Route path="/categories" component={Category} isPrivate />
      <Route path="/workspaces" component={Workspace} isPrivate />
      <Route path="/reports" component={Report} isPrivate />

      <Route path="/permissions" component={Permission} isPrivate />
      <Route path="/users" component={User} isPrivate />
      <Route path="/rls" component={Rls} isPrivate />
      <Route path="/config" component={Config} isPrivate />

      <Route
        path="/embedded/:id/:report"
        component={Embedded}
        isPrivate
        exact
      />

      {/* <Route path="/profile" component={Profile} isPrivate /> */}
    </Switch>
  );
};

export default Routes;
