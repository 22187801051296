import api from 'services/api';

import { ILayout, ILayoutForm } from './interfaces';

const createFormLayout = (layout: ILayoutForm) => {
  const formData = new FormData();

  const data: ILayoutForm = {
    header_title: layout.header_title,
    page_title: layout.page_title,
    theme_light: '{}',
    theme_dark: '{}',
  };

  if (layout.logo_upload?.length) {
    const { type } = layout.logo_upload[0];

    const newFile = new File(
      [layout.logo_upload[0]],
      `logo.${type.split('/')[1]}`,
      { type },
    );

    formData.append('images', newFile);
  }

  if (layout.logo_header_upload?.length) {
    const { type } = layout.logo_header_upload[0];

    const newFile = new File(
      [layout.logo_header_upload[0]],
      `logo_header.${type.split('/')[1]}`,
      { type },
    );

    formData.append('images', newFile);
  }

  if (layout.icon_upload?.length) {
    const { type } = layout.icon_upload[0];

    const newFile = new File(
      [layout.icon_upload[0]],
      `icon.${type.split('/')[1]}`,
      { type },
    );

    formData.append('images', newFile);
  }

  formData.append('data', JSON.stringify(data));

  return formData;
};

const getLayout = async (): Promise<ILayout> => {
  const config = await api.get<ILayout>(`/layout/by/tenant`);

  const data: ILayout = {
    ...config.data,
  };

  return data;
};

const createLayout = async (config: ILayoutForm): Promise<void> => {
  const data = createFormLayout(config);

  await api.post('/layout', data);
};

const updateLayout = async (
  id: string,
  config: ILayoutForm,
): Promise<ILayout> => {
  const data = createFormLayout(config);

  const response = await api.put<ILayout>(`/layout/${id}`, data);

  return response.data;
};

export { getLayout, createLayout, updateLayout };
